<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="notice-container">
      <div class="d-flex justify-content-end mx-auto">

        <p class="range-indicator">大会表示期間</p>
        <DatePicker ref="datePicker" :opens="calenderLocation" v-bind:date-range="dateRange"
                    @change-date="updateData"></DatePicker>
      </div>
      <div v-for="(tournament, index) in tournamentList" v-bind:key="'tournamentId-'+ tournament.tournamentId"
           class="card card-tournament mt-3">
        <div class="card-header">
          <span v-if="tournament.tournamentStatus === 3" class="badge bg-light color-88">終了</span>
          <span v-if="tournament.tournamentStatus === 2" class="badge badge-danger badge-period">期間中</span>
          <span v-if="tournament.tournamentStatus === 1" class="badge badge-danger-light">近日開始</span>
          <span class="mx-3">{{ tournament.tournamentName }}</span>
          <h6 class="float-end mt-1"><i class="fa fa-calendar-check"></i><span class="inputPeriod">入力期間:</span>
            {{ tournament.inputStartAt }}〜{{ tournament.inputEndAt }}</h6>
        </div>
        <div class="card-body pl-3">
          <div v-if="tournament.tournamentStatus === 2" class="row m-l-0 m-b-20">
            <div class="width-130 px-0 justify-content-between">
              <select v-model="showPeriod[tournament.tournamentId]"
                      :value="showPeriod[tournament.tournamentId]" class="form-control form-select">
                <option value="0">過去14日間</option>
                <option value="1">本日</option>
              </select>
            </div>
            <div class="col text-end text-clamp-1 p-l-5 m-t-5">
              利用者登録フォーム:<a :href="tournament.registerURL" class="mx-2 color-pink text-decoration-none"
                           target="_blank">{{ tournament.registerURL }}</a>
            </div>
          </div>
          <div v-if="tournament.tournamentStatus !== 2" class="row m-l-0 m-b-20">
            <div class="width-130 px-0 justify-content-between">
              <select v-model="showPeriod[tournament.tournamentId]"
                      :value="showPeriod[tournament.tournamentId]" class="form-control form-select" disabled>
                <option value="0">過去14日間</option>
                <option value="1">本日</option>
              </select>
            </div>
            <div class="col text-end text-clamp-1 p-l-5 m-t-5">
              利用者登録フォーム:<a :href="tournament.registerURL" class="mx-2 color-pink text-decoration-none"
                           target="_blank">{{ tournament.registerURL }}</a>
            </div>
          </div>

          <div v-if="showPeriod[tournament.tournamentId] === '0'" class="row mx-auto button-group">
            <div class="col">
              <div class="button-group-container">
                <div class="status-num">
                  <span v-html="showHealthStatus('入場拒否')"></span>
                  <span class="mx-2">{{ tournament.last14daysHealthStatusCountRejection }}名</span>
                </div>
                <div class="status-num">
                  <span v-html="showHealthStatus('異常あり')"></span>
                  <span class="mx-2">{{ tournament.last14daysHealthStatusCountAbnormality }}名</span>
                </div>
                <div class="status-num">
                  <span v-html="showHealthStatus('未入力')"></span>
                  <span class="mx-2">{{ tournament.last14daysHealthStatusCountNoInput }}名</span>
                </div>
                <div class="status-num">
                  <span v-html="showHealthStatus('大会承認済')"></span>
                  <span class="mx-2">{{ tournament.last14daysHealthStatusCountAcceptation }}名</span>
                </div>
                <div class="status-num">
                  <span v-html="showHealthStatus('異常なし')"></span>
                  <span class="mx-2">{{ tournament.last14daysHealthStatusCountNormality }}名</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showPeriod[tournament.tournamentId] === '1'" :id="'today' + index"
               class="row mx-auto button-group">
            <div class="col">
              <div class="button-group-container">
                <div class="status-num">
                  <span v-html="showHealthStatus('入場拒否')"></span>
                  <span class="mx-2">{{ tournament.todayHealthStatusCountRejection }}名</span>
                </div>
                <div class="status-num">
                  <span v-html="showHealthStatus('異常あり')"></span>
                  <span class="mx-2">{{ tournament.todayHealthStatusCountAbnormality }}名</span>
                </div>
                <div class="status-num">
                  <span v-html="showHealthStatus('未入力')"></span>
                  <span class="mx-2">{{ tournament.todayHealthStatusCountNoInput }}名</span>
                </div>
                <div class="status-num">
                  <span v-html="showHealthStatus('大会承認済')"></span>
                  <span class="mx-2">{{ tournament.todayHealthStatusCountAcceptation }}名</span>
                </div>
                <div class="status-num">
                  <span v-html="showHealthStatus('異常なし')"></span>
                  <span class="mx-2">{{ tournament.todayHealthStatusCountNormality }}名</span>
                </div>
              </div>
            </div>
          </div>
          <div class="cta-group">
            <button class="btn btn-primary height-30 font-13 pt-1 text-center"
                    @click="scanResultCTA(tournament.tournamentId)">入場管理
            </button>
            <button class="btn btn-primary height-30 font-13 pt-1 text-center mx-4"
                    @click="jumpRecord(tournament.tournamentId)">健康記録参照
            </button>
          </div>
        </div>
      </div>
      <div v-if="tournamentList.length === 0" class="no-competetion mt-3">
        <p class="no-competetion-msg">{{ $t("record.recordList.noData") }}</p>
      </div>
    </div>
  </div>


</template>

<script>

import DatePicker from '../components/datepicker/DatePicker'
import {mapGetters} from 'vuex'
import Common from 'jsadminclient/common'
import Helper from 'jsadminclient/helper'

export default {
  name: "Home",
  components: {DatePicker},
  data() {
    return {
      loading: true,
      dateRange: {
        startDate: null,
        endDate: null
      },
      calenderLocation: 'Home',
      tournamentList: {},
      showPeriod: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  mounted() {
    this.dateRange.startDate = Common.getHomeStartDate()
    this.dateRange.endDate = Common.getHomeEndDate()
    if (this.dateRange.startDate === null || this.dateRange.endDate === null) {
      this.$refs.datePicker.setLatest14DaysAsDefaultSelection(false)
      this.dateRange.startDate = this.$refs.datePicker.dateRange.startDate
      this.dateRange.endDate = this.$refs.datePicker.dateRange.endDate
    } else {
      this.$refs.datePicker.setDateRange(this.dateRange, false)
    }

    this.loading = false
    this.loadTournamentList()
  },
  methods: {
    showHealthStatus(healthStatus) {
      return Helper.showHealthStatus(healthStatus)
    },
    updateData(dateRange) {
      this.dateRange = dateRange
      Common.saveHomeStartDate(dateRange.startDate)
      Common.saveHomeEndDate(dateRange.endDate)

      this.loadTournamentList()
    },
    customFormatter(date) {
      return moment(date).format('YYYY/MM/DD');
    },
    scanResultCTA(tournamentId) {
      this.$router.push({name: 'Scan', params: {tournamentId: tournamentId}})
    },
    jumpRecord(tournamentId) {
      const tournament = this.tournamentList.find(tournament => tournament.tournamentId === tournamentId)
      this.$router.push({name: 'Record', params: {tournament: tournamentId}})
    },
    async loadTournamentList() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token))
        var result = await api.tournamentsHomeGetAsync(
            this.dateRange.startDate,
            this.dateRange.endDate
        )
        if (result.items) {
          this.loading = false;
          this.tournamentList = result.items;
        }
        result.items.forEach((item) => {
          this.showPeriod.push({id: item.tournamentId})
          this.showPeriod[item.tournamentId] = '0';
        })

      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.badge-danger-light {
  color: #C95D6C;
  background: #F2ECF0;
}

.badge-period {
  background-color: #C95D6C;
}

.inputPeriod {
  color: #333333;
}

.range-indicator {
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  padding-right: 20px;
  color: #333333;
}

.no-competetion {
  border: 1px solid #DACED1 !important;
  height: 280px !important;
}

.no-competetion-msg {
  text-align: center;
  margin-top: 120px;
  color: #888888;
  font-size: 13px;
}

.tournament-list-container {
  margin-bottom: 80px;
}

select {
  padding-top: 3px;
  width: 130px;
  height: 32px !important;
}

.seperator {
  font-size: 20px;
  color: #333333;

}

.from-date {
  width: 160px !important;
  height: 32px !important;
  background-color: #F2ECF0;
}

.to-date {
  width: 160px !important;
  height: 32px !important;
  background-color: #F2ECF0;
}

.card-header {
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 31px;
  color: #333333;
}

.card svg, h6 > i {
  margin-right: 5px;
}

.card-body > p {
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #333333;
}

.card-body > p:last-child {
  margin-bottom: 0px;
}

.tournament-card-body {
  padding: 0;
}

.tournament-card-body > .row {
  padding-left: 20px;
  padding-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin: auto;
}

.tournament-card-body > .row .col-md-6 {
  display: flex;
  margin-left: -10px;
}

.tournament-card-body > .row > .col-md-6:first-child > .col-3 {
  padding-left: 5px;
}

.tournament-card-body > .row > .col-md-6:last-child > .col-9 .fa-info-circle {
  padding-right: 0px;
  float: right;
}

.custom-bg-light {
  background: #EBEBEB !important;
  color: #888888;
}

.end-fa-circle {
  color: #888888;
}

.during-the-period-fa-circle {
  color: #2663D1;
}

.coming-soon-fa-circle {
  color: #C9E5F7;
}

.custom-bg-primary {
  background: #2663D1 !important;
}

.custom-bg-secondary {
  background: #D9E5F7 !important;
  color: #2663D1;
}

.fa-info-circle {
  color: #2663D1;
  padding-right: 15px;
}

.alert-danger {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
}

.fa-exclamation-triangle {
  color: #721C24;
  margin-right: 5px;
  margin-left: 5px;
}

.fa.fa-calendar-check {
  color: #BBBBBB;
}

.back-button {
  width: 24px;
  height: auto;
}

.tournament-info {
  padding: 10px;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  position: relative;
  width: 73%;
  left: 10%;
}

li {
  list-style-type: none;
}

.end-fa-circle, .during-the-period-fa-circle, .coming-soon-fa-circle {
  display: none;
}

@media only screen and (max-width: 1399px) {

  .tournament-card-body > .row > .col-md-6:last-child > .col-9 .fa-info-circle {
    padding-right: 0;
  }

}

@media only screen and (max-width: 1199px) {

  .tournament-card-body > .row > .col-md-6:last-child > .col-9 .fa-info-circle {
    padding-right: 0;
  }

}

@media only screen and (max-width: 992px) {

  .inputPeriod {
    display: none;
  }

  .tournament-card-body > .row > .col-md-6:first-child > .col-3 {
    display: none;
  }

  .tournament-card-body > .row > .col-md-6:last-child > .col-9 .fa-info-circle {
    padding-left: 25px;
    padding-right: 0;
    float: none;
  }

  .fa-circle-o {
    color: #BBBBBB;
  }

  .end-fa-circle, .during-the-period-fa-circle, .coming-soon-fa-circle {
    display: inline;
    padding-right: 5px;
  }

}

@media only screen and (max-width: 768px) {

  .inputPeriod {
    display: none;
  }

  .tournament-card-body > .row > .col-md-6:first-child > .col-3 {
    display: none;
  }

  .fa-circle-o {
    color: #BBBBBB;
  }

  .end-fa-circle, .during-the-period-fa-circle, .coming-soon-fa-circle {
    display: inline;
    padding-right: 5px;
  }

}

@media only screen and (max-width: 576px) {

  .tournament-card-body > .row > .col-md-6:last-child > .col-9 .fa-info-circle {
    padding-left: 5px;
  }

}

.card {
  &-tournament {
    .button-group {
      margin-bottom: 12px;
      border: 1px solid #DACED1;
      border-radius: 4px;

      .col {
        padding: 12px 16px;
      }

      .button-group-container {
        display: inline;
      }

      .status-num {
        margin-top: 4px;
        margin-bottom: 4px;
        display: inline-block;

        &:not(:last-child) {
          min-width: 178px;
        }
      }
    }
  }
}

</style>
