import {defaults, apiConfig} from '../../constants'

export default class {
  constructor() {

  }

  /*
  |--------------------------------------------------------------------------
  | Get API configuration object
  |--------------------------------------------------------------------------
   * @param apiToken
   * @returns {ApiConfig}
   */
  static apiConfig(apiToken = null) {
    apiConfig.headers['X-API-Token'] = apiToken
    return new AdminApi.ApiConfig(
      apiConfig.basePath,
      apiConfig.headers
    )
  }

  /*
  |--------------------------------------------------------------------------
  | Get a list of numbers with zero padding
  |--------------------------------------------------------------------------
   * @param start Starting number in integer
   * @param stop Ending number in integer
   * @param nDigits Number of digits with the zeros in integer
   * @param step Step size
   * @returns Array
  */
  static getRange(start, end, nDigits= 2, step = 1) {
    return [...Array(end + 1 - start).keys()]
      .filter(i => !(i % Math.round(step)))
      .map((v) => {
        return (new Array(nDigits + 1).join('0') + (start + v)).slice(-nDigits)
      })
  }

  /*
  |--------------------------------------------------------------------------
  | Get formatted response for Datatable pagination
  |--------------------------------------------------------------------------
   * @param response
   * @returns Object
   */
  static formatResponse(response) {
    const result = response
    const paging = result.paging

    const current_page = paging.page
    const per_page = paging.pageParCount
    const from = per_page * (current_page - 1) + 1
    const last_page = paging.pageCount
    const to = per_page * current_page
    const total = paging.totalCount
    const first_page_url = "?page=1"
    const last_page_url = "?page=" + last_page
    const next_page_url = current_page === last_page ? '' : "?page=" + (current_page + 1)
    const path = ""
    const prev_page_url = current_page === from ? '' : "?page=" + (current_page - 1)

    const sort = result.sort
    const order = result.order

    const data = response.items

    return {
      sort: sort,
      order: order,
      current_page: current_page,
      per_page: per_page ? per_page : datatable.default.perPage,
      from: from,
      to: to,
      last_page: last_page,
      first_page_url: first_page_url,
      last_page_url: last_page_url,
      prev_page_url: prev_page_url,
      next_page_url: next_page_url,
      path: path,
      total: total,
      data: data
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Show day from date
  |--------------------------------------------------------------------------
  */
  static showDay(date, locale, format = 'ddd', extraCss = '') {
    if (!date) {
      return ''
    }
    if (!locale || typeof locale === 'undefined') {
      locale = moment.locale()
    }
    return '<span class="placeholder-day ' + extraCss + '">(' + this.getDay(date, locale, format) + ')</span>'
  }

  /*
  |--------------------------------------------------------------------------
  | Get day from date
  |--------------------------------------------------------------------------
  */
  static getDay(date, locale, format = 'ddd') {
    if (!date) {
      return ''
    }
    if (!locale || typeof locale === 'undefined') {
      locale = moment.locale()
    }
    return moment(date).locale(locale).format(format)
  }

  /*
  |--------------------------------------------------------------------------
  | Show date
  |--------------------------------------------------------------------------
  */
  static showDate(date, format = 'MM/DD', locale, extraCss = '') {
    return '<span class="placeholder-date ' + extraCss + '"><i class="fas fa-calendar-check"></i>' +
      this.getDay(date, locale, format) +
      '</span>'
  }

  /*
  |--------------------------------------------------------------------------
  | Show Body Temperature
  |--------------------------------------------------------------------------
  */
  static showBodyTemp(bodyTemp) {
    if (bodyTemp === "") {
      return ""
    }
    return bodyTemp > 0 ? bodyTemp + "℃" : '— —℃'
  }

  // static showBodyTemp(bodyTemp, extraCss = '') {
  //   return '<span class="placeholder-thermometer ' + extraCss + '">' +
  //     '<i class="fas fa-thermometer-half" aria-hidden="true"></i>' +
  //     (bodyTemp > 0 ? bodyTemp : '——') + '℃' +
  //     '</span>'
  // }

  /*
  |--------------------------------------------------------------------------
  | Get button html by health status Id
  |--------------------------------------------------------------------------
  | Status Id:
  | 0: All(Default) / すべて
  | 1: Rejection / 入場拒否
  | 2: Abnormality / 異常あり
  | 3: No Input / 未入力
  | 4: Acceptation / 大会承認済
  | 5: Normality / 異常なし
  */
  static showHealthStatus(healthStatus, extraCss = '') {

    if (healthStatus === null || typeof healthStatus == 'undefined') {
      return ''
    }
    let reasons = defaults.enterStatus.reasons
    if (isNaN(healthStatus)) {
      const getKeyByValue = (obj, value) =>
        Object.keys(obj).find(key => obj[key].value === value)
      healthStatus = getKeyByValue(reasons, healthStatus);
    }

    let reason = reasons[healthStatus]
    return '<span class="badge badge-status border-0 height-28 width-104 ' + reason.button + ' ' + extraCss + '">' +
      '<i class="' + reason.icon + '"></i>' + reason.value +
      '</span>'
  }

  /*
  |--------------------------------------------------------------------------
  | Convert alerts array to formatted html
  |--------------------------------------------------------------------------
  */
  static showAlerts(alerts, extraCss = '') {
    let html = '<div class="alerts">'
    for (var i = 0; i < alerts.length; i++) {
      html +=
        '<span class="badge badge-status border-0 bg-light color-33 m-r-5 m-b-5 '
        + extraCss + '">' +
        alerts[i] +
        '</span>'
    }
    html += '</div>'
    return html
  }

  /*
  |--------------------------------------------------------------------------
  | Show CTA
  |--------------------------------------------------------------------------
  */
  static showCTA(extraCss = 'fa-info-circle') {
    return '<i class="fas ' + extraCss + ' font-16 text-info"></i>'
  }

  /*
  |--------------------------------------------------------------------------
  | Show enter status
  |--------------------------------------------------------------------------
  | enterStatus:
  | 0 = No input,
  | 1 = Good,
  | 2 = Bad,
  */
  static showEnterStatus(enterStatus, extraCss = '') {
    if (enterStatus === null || typeof enterStatus === 'undefined') {
      return ''
    } else if (enterStatus === 0 || enterStatus.toString().toLowerCase() === 'no input') {
      return '<div class="enter-status enter-status-empty ' + extraCss + '">' +
        '<i class="fas fa-minus font-15 color-88 m-t-50 m-l-50"></i>' +
        '</div>'
    } else if (enterStatus === 1 || enterStatus.toString().toLowerCase() === 'good') {
      return '<div class="enter-status enter-status-good ' + extraCss + '">' +
        '<i class="far fa-circle text-success"></i>' +
        '</div>'
    } else if (enterStatus === 2 || enterStatus.toString().toLowerCase() === 'bad') {
      return '<div class="enter-status enter-status-bad ' + extraCss + '">' +
        '<i class="fas fa-times text-danger"></i>' +
        '</div>'
    } else if (enterStatus === 3) {
      return '<div class="enter-status" style="text-align: center; padding-top: 55px;">' +
        '<span>ー</span>' +
        '</div>'
    } else {
      return '<div class="enter-status enter-status-undefined ' + extraCss + '"></div>'
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Show tournament status
  |--------------------------------------------------------------------------
  | tournamentStatus:
  | 1 = Coming soon / 近日開始
  | 2 = During the duration / 期間中
  | 3 = Ended / 終了,
  */
  static showTournamentStatus(tournamentStatus, extraCss = '') {
    if (tournamentStatus === null || typeof tournamentStatus === 'undefined') {
      return ''
    } else if (tournamentStatus === 1 || tournamentStatus.toString().toLowerCase() === '近日開始') {
      return '<span class="badge tournament-status-upcoming border-0 ' + extraCss + '">近日開始</span>'
    } else if (tournamentStatus === 2 || tournamentStatus.toString().toLowerCase() === '期間中') {
      return '<span class="badge tournament-status-ongoing border-0 ' + extraCss + '">期間中</span>'
    } else if (tournamentStatus === 3 || tournamentStatus.toString().toLowerCase() === '終了') {
      return '<span class="badge tournament-status-ended border-0 ' + extraCss + '">終了</span>'
    } else {
      return '<span class="badge tournament-status-undefined border-0 ' + extraCss + '">終了</span>'
    }
  }
}
